export const environment = {
    production: true,
    apiUrl: 'https://portal.api.cloudfactory.dk/',
    logoUrl: 'https://portal.api.cloudfactory.dk/v2/partners/Partners/{id}/Logo',
    vmConsoleUrl: 'https://terminal.cloudfactory.dk',
    dropboxApiUrl: 'https://portal.api.cloudfactory.dk/dropbox',
    dropboxApiUrlV2: 'https://portal.api.cloudfactory.dk/dropbox/v2',
    catalogueApiUrl: 'https://portal.api.cloudfactory.dk/v2/catalogue/',
    microsoftV2API: 'https://portal.api.cloudfactory.dk/v2/microsoft',

    auth: {
        authorizationParams: {
            audience: 'mylicenses-api-prod',
            redirect_uri: `${window.location.origin}`,
        },
        domain: 'login.customerportalauth.com',
        clientId: 'S3h1sBDxToFiiaT2q2ZogqAK9Z1FZLyk',
        useRefreshTokens: true,
        cacheLocation: 'localstorage' as const
    },
};
