import {Injectable} from '@angular/core';
import {createAuth0Client} from '@auth0/auth0-spa-js';
import {Auth0Client} from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import {BehaviorSubject, from, Observable, ReplaySubject, throwError} from 'rxjs';
import {catchError, concatMap, shareReplay, tap} from 'rxjs/operators';
import {ICustomer, IRole, PermissionNode} from 'src/modules/customer/model/customer.model';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    // Create an observable of Auth0 instance of client
    auth0Client$ = (from(
        createAuth0Client(environment.auth),
    ) as Observable<Auth0Client>).pipe(
        shareReplay(1), // Every subscription receives the same shared value
        catchError(err => throwError(() => new Error(err))),
    );
    // Define observables for SDK methods that return promises by default
    // For each Auth0 SDK method, first ensure the client instance is ready
    // concatMap: Using the client instance, call SDK method; SDK returns a promise
    // from: Convert that resulting promise into an observable
    isAuthenticated$ = this.auth0Client$.pipe(
        concatMap((client: Auth0Client) => from(client.isAuthenticated())),
        tap(res => {
            this.loggedIn = res;
            if (this.loggedIn) {
                this.getRoles();
            }
        }),
    );

    // Create subject and public observable of user profile data
    private userProfileSubject$ = new BehaviorSubject<any>(null);
    userProfile$ = this.userProfileSubject$.asObservable();
    // Create a local property for login status
    loggedIn: boolean = null;
    private apiUrl = environment.apiUrl + 'Authenticate';
    private Roles = new ReplaySubject<IRole[]>(1);
    private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);

    constructor(private http: HttpClient) {
        // On initial load, check authentication state with authorization server
        // Set up local auth streams if user is already authenticated
        this.localAuthSetup();
    }

    private getRoles() {
        this.http.get<IRole[]>(this.apiUrl + '/Roles').subscribe(data => {
            this.Roles.next(data);
        });
    }

    public hasPermissionObservable(inputNode: PermissionNode): Observable<boolean> {
        const observable = new ReplaySubject<boolean>(1);
        this.Roles.subscribe((roles) => {
            let result = false;
            roles.forEach((item) => {
                if (item.permissions.findIndex(x => x === inputNode) > -1) {
                    result = true;
                }
            });
            observable.next(result);
        });
        return observable;
    }

    private localAuthSetup() {
        this.auth0Client$.pipe(
            tap(async (client: Auth0Client) => {
                if (window.location.search.includes('code=')) {
                    await client.handleRedirectCallback();
                    window.history.replaceState({}, document.title, window.location.pathname);
                }

                const isAuthenticated = await client.isAuthenticated();
                this.isAuthenticatedSubject$.next(isAuthenticated);

                if (isAuthenticated) {
                    const user = await client.getUser();
                    this.userProfileSubject$.next(user);
                }
            })
        ).subscribe();
    }


    login(redirectPath: string = '/') {
        // A desired redirect path can be passed to login method
        // (e.g., from a route guard)
        // Ensure Auth0 client instance exists
        this.auth0Client$.subscribe((client: Auth0Client) => {
            // Call method to log in
            client.loginWithRedirect({
                openUrl: async (url) => {
                    window.location.replace(url);
                }
            });
        });
    }

    logout() {
        // Ensure Auth0 client instance exists
        this.auth0Client$.subscribe((client: Auth0Client) => {
            client.getIdTokenClaims().then(claims => {
                client.logout({
                    clientId: environment.auth.clientId,
                    openUrl: (url) => {
                        if (url === null || url === undefined || url === '') {
                            window.location.replace(claims.logout_url);
                        } else {
                            window.location.replace(url);
                        }
                    },
                    logoutParams: {
                        returnTo: 'http://' + location.host,
                        federated: true
                    }
                });
            });
        });
    }

    getTokenSilently$(options?): Observable<any> {
        return this.auth0Client$.pipe(concatMap((client: Auth0Client) => from(client.getTokenSilently(options))));
    }

    getCustomerProfile(): Observable<ICustomer> {
        return this.http.get<ICustomer>(this.apiUrl + '/Customer');
    }
}
